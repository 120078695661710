import React from "react";
import { graphql, Link } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import BlogContent from "../components/blog-content";
import TableOfContents from '../components/table-of-contents';
import Breadcrumbs from "../components/breadcrumbs"
import Thumbnail from "../components/thumbnail";
import resolveUrl from "../utils/urls/resolve-url";
import Layout from "../components/layout";

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({ ...props }) => (
  <BaseHead {...props} description={props.data?.mysqlTerms?.description}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/term/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const Apps = ({ 
  data: { allMysqlApps, mysqlTerms }, 
  pageContext: { breadcrumbs },
  location: { pathname },
}) => {
  return (
    <Layout pathname={pathname}>
    <div className={[b.container, b.pt3,'breadcrumbs-mobile',b.ptLg5,"scroll-inner"].join(" ")}>
      <Breadcrumbs theme='white' items={breadcrumbs} />
    </div>
    <div className={[b.container, b.pbLg5, b.pt3].join(" ")}>
      <div className={["blueToGray", b.mb4,b.mt2].join(" ")}>
        <h1 className={["blackToWhite",'big-title', b.mb2,b.mt4].join(" ")}>{mysqlTerms.title}</h1>
      </div>
      <div className={[b.row].join(' ')}>
        {allMysqlApps.nodes.map(({url, bank, description, name, mysqlImage}, i) => (
          <div key={i} className={[b.colLg3,b.colMd6,b.my3].join(' ')}>
            <div className={["home-page-app-box",b.h100].join(' ')}>
              <div className={[b.dFlex,b.justifyContentCenter,b.alignItemsCenter,'apps-bg'].join(' ')}>
                <Thumbnail image={mysqlImage} className={b.mt3} alt={name + ' | akredo.pl'}/>
              </div>
              <div className={[b.p3,b.pb4,'blackToWhite'].join(' ')}>
                <Link to={bank.specific_url ? resolveUrl(bank.specific_url, url) : resolveUrl(bank.prefix_url, bank.url, url)} className="blackToWhite">
                  <div className={['font-size-18',b.mb2,'tp-name'].join(' ')}>{name}</div>
                </Link>

                <BlogContent content={description} className='font-size-13'/>
              </div>
            </div>
          </div>
        ))}
      </div>
      {(mysqlTerms.description || mysqlTerms.description_additional || mysqlTerms.description_alt) && <div className={`${b.row} product-page blog-content`}>
        <div className={[b.colXl4, b.mb3, b.orderXlLast].join(' ')}>
          <TableOfContents selector=".post-content h2[id]" />
        </div>

        <section className={`${b.colXl8} blackToGray dark-theme-listing ${b.mb4}`}>
          {<BlogContent content={`${mysqlTerms.description} ${mysqlTerms.description_alt} ${mysqlTerms.description_additional}`} />}
        </section>
      </div>}
    </div>
    
    </Layout>
  )
}

export default Apps

export const query = graphql `
  query AppsQuery($mysqlId: Int!) {
    allMysqlApps {
      nodes {
        url
        description
        name
        mysqlImage {
          childImageSharp {
            gatsbyImageData(width: 160)
          }
        }
        bank {
          specific_url
          prefix_url
          url
        }
      }
    }
    allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "term" }}) {
      nodes {
        ...SeoData
      }
    }
  mysqlTerms(mysqlId: {eq: 289}) {
    description
    title
    term_normalized
    description_alt
    description_additional
    path
  }
}
`